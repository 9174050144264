<template>
    <div>
        <Panel module="Funil" layout="large" :showFilter="false" :showSearch="false" :showButtons="false">
            <div slot="content-main">
                <br />
                <Button _key="btnFunnelCreate" title="Funil" classIcon="fas fa-plus-circle" type="primary" size="small"
                    :clicked="create" />
                <FunnelList />
            </div>
        </Panel>
        <Modal title="Funil" :width="750" :height="750" v-show="showModal('funnel')">
            <FunnelCreateUpdate />
        </Modal>
    </div>
</template>

<script>



import Panel from "@nixweb/nixloc-ui/src/component/layout/Panel";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";

import FunnelList from '../../../components/modules/crm/funnel/FunnelList.vue'
import FunnelCreateUpdate from '../../../components/modules/crm/funnel/FunnelCreateUpdate.vue'

import { mapMutations, mapGetters } from "vuex";

export default {
    name: "FunnelView",
    components: { Panel, Loading, Button, Modal, FunnelCreateUpdate, FunnelList },
    mounted() {
        this.removeLoading(["panel"]);
    },
    computed: {
        ...mapGetters("generic", ["showModal", "event"]),
    },
    methods: {
        ...mapMutations("generic", ["addEvent", "openModal", "removeLoading"]),
        create() {
            this.openModal("funnel");
            this.addEvent({ name: "funnelCreate" });
            this.removeLoading(["btnFunnelCreate"]);
        }
    },
    watch: {
        event: {
            handler(event) {
                if (event.name == "funnelUpdate") {
                    this.openModal("funnel");
                }
            },
            deep: true,
        },
    },
}
</script>
<style scoped></style>